import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
//import 'bootstrap/dist/css/bootstrap.min.css';

function Doublegame() {
    const [dados, setDados] = useState([]);

    const momentRef = useRef(moment);

    const currentDate = moment();

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchData();
          }, 2000);
          return () => clearInterval(intervalId);
    }, []);

    //busca as atualizações de resultados da blaze via api
    async function fetchData() {
        
        const resposta = await axios.get('https://rifa-json.belechedev.com/double2.php');
        if(resposta.data.status){
            setDados(resposta.data.data);
        }
        
    }

    // Função para subtrair horas da data atual
    function dateNova(horas) {
        const nDate = moment();
        return nDate.subtract(horas, 'hours');

    }

    // Função para contar a quantidade de itens "brancos" em uma hora específica
    function contaBranco(dia, hora) {
        let brancos = 0;
        dados.forEach(item => {
            if(momentRef.current(item.date).format('DD') == dia && momentRef.current(item.date).format('HH') == hora && item.color == 'white'){
                brancos++;
            }
        });
        return brancos;
    }

    // Função para formatar a div com as informações dos itens
    function formatDiv(id, color, roll, date) {

        const divclass = 'div_anom div_' + color;

        return (
            <div className='double_point'>
                <div className={divclass} key={id} ><p className='texto_p'>{roll}</p></div>
                <div className='div_date'>{momentRef.current(date).format('HH:mm:ss')}</div>
            </div>
        );
        
    }

    return (
        <div className='content'>
            {/* Renderiza as informações de cada hora */}
            <div className="zero_per_hour">
                {[...Array(24).keys()].map(hour => (
                <div className="zero_hour" key={hour}>
                    <p className="p_hour">{dateNova(hour).format('HH:00')}</p>
                    <p className="p_whitecount">
                    {contaBranco(dateNova(hour).format('DD'), dateNova(hour).format('HH'))} brancos
                    </p>
                </div>
                ))}
            </div>
            {/* Renderiza os itens */}
            <div className='double_simples'>
                {dados.map((item) => (
                formatDiv(item.id, item.color, item.roll, item.date)
                ))}
            </div>
        </div>
    );

}

export default Doublegame;